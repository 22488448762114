<template>
  <div class="FuLin" v-loading="loading">
    <!--春季：3-8月-->
    <div class="spring" v-if="nowMoth >= 3 && nowMoth <= 8">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key='"spring"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/FuLin/spring_jianjie.png" alt="">
        <div class="desc">
          <p>
            富林镇2021年枇杷种植约2800余亩，主要种植地为流沙河社区、青富村、富塘村、市荣社区和饮泉社区，年产量达252万斤，年产值约2400万元。
          </p>
          <p>
            富林镇2021年黄果柑种植约2500余亩，主要种植地为西园社区、流沙河社区、青富村、富塘村、市荣社区和饮泉社区，年产量达775万斤，年产值约1550万元。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/orange.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#DC6A06"
      ></GovernmentServices>
    </div>
    <!-- 秋季：9-2月 -->
    <div
      class="autumn"
      v-else-if="
        (nowMoth >= 9 && nowMoth <= 12) || (nowMoth >= 1 && nowMoth <= 2)
      "
    >
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/FuLin/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in autumnImgList" :key='"autumn"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/FuLin/autumn_jianjie.png" alt="">
        <div class="desc">
          富林镇2021年苹果种植约3400余亩，主要种植地为青富村、富塘村和太平村，年产量达1088万斤，年产值约2720万元。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/red.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#9D0012"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "FuLin",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "富林镇",
      keywords: "",
      springImgList:[
        {imgUrl:require('../../../assets/img/FuLin/banner.jpg')},
        {imgUrl:require('../../../assets/img/FuLin/spring_banner1.jpg')},
        {imgUrl:require('../../../assets/img/FuLin/spring_banner2.jpg')},
      ],
      autumnImgList:[
        {imgUrl:require('../../../assets/img/FuLin/banner.jpg')},
        {imgUrl:require('../../../assets/img/FuLin/autumn_banner.jpg')},
      ]
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix{
  color: #E79E5F;
  font-size: 20px;
}

/deep/.autumn .el-input__suffix{
  color: #CC2219;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #E79E5F;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #CC2219;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>