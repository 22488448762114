<template>
  <div class="FuQuan" v-loading="loading">
    <!-- 夏季：4-6月 -->
    <div class="summer">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in bannerImgList" :key='index'>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
         <!-- <img
          width="100%"
          src="../../../assets/img/FuQuan/banner.jpg"
          alt=""
          srcset=""
        /> -->
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/FuQuan/jianjie.png" alt="">
        <div class="desc">
          <p>
            枇杷适宜温暖湿润的气候，在生长发育过程中要求较高温度，年平均温度12~15°C，冬季不低于-5°C，花期及幼果期不低于0°C为宜。
          </p>
          <p>
            枇杷是美观的果树，果味酸甜，供鲜食、蜜饯与酿酒用；树叶晒干去毛可供药用，有化痰止咳、和胃降气之效；枇杷木材红棕色，可制作木梳、手杖、农具柄等。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/orange.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#DC6E0B"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
// import { getServerDate, matterQuery,getVillages } from "@/api/map/index"
import { mixin } from "../../../mixin";
export default {
  name: "FuQuan",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "富泉镇",
      keywords:'',
      bannerImgList:[
        {imgUrl:require('../../../assets/img/FuQuan/banner.jpg')},
        {imgUrl:require('../../../assets/img/FuQuan/banner1.jpg')},
      ]
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

/deep/.summer .el-input__suffix{
  color: #DC6860;
  font-size: 20px;
}
/deep/.el-carousel__indicator.is-active button {
  background-color: #DC6860;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>