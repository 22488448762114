<template>
  <div class="ShunHe" v-loading="loading">
    <!-- 秋季：7-9月 -->
    <div class="autumn">
      <div class="banner">
        <!-- <img width="100%" src="../../../assets/img/ShunHe/banner.jpg" alt="" srcset=""> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in autumnImgList" :key="'autumn'+index">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/ShunHe/jianjie.png" alt="">
          <div class="desc">
            <p>
              “寻南山”，意为上南山，寻核桃。我们不仅要寻找优质原生态薄壳核桃，更要寻找当地人自强不息，通过发展核桃产业脱贫致富的精神，寻找不忘初心，不辱使命的扶贫工作干部的足迹。通过他们不懈努力，帮助该村摆脱了贫困面貌，让这种自强不息，不忘党恩的精神永远发扬传承下去!
            </p>
            <p>
              “寻南山”，核桃地处干热新河谷地带的大渡河大峡谷境内，常年光照充足，盛产优质核桃。所生产的核桃皮薄，果仁饱满，鲜核桃鲜、香、嫩，口感很佳，该产业带动了村经济发展，是巩固脱贫攻坚成效的支柱产业。
            </p>
          </div>
        
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/brown.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices :mapId="mapId" :name="name" color="#7B4411"></GovernmentServices>
    </div>

  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index"
import { mixin } from "../../../mixin";
export default {
  name: 'ShunHe',
  components: { GovernmentServices },
  data() {
    return {
      matterList: [],  //事项列表
      villages: [],  //乡镇列表
      name:'顺河彝族乡',
      keywords:'',
      autumnImgList: [
        { imgUrl: require("../../../assets/img/ShunHe/banner.jpg") },
        { imgUrl: require("../../../assets/img/ShunHe/banner1.jpg") },
      ],
    };
  },
  mixins:[mixin],
  created() {
    this.$emit('public_header', false);

  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
/* .banner img{
  height: 100%;
} */
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.autumn .el-input__suffix{
  color: #7E3C29;
  font-size: 20px;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #7E3C29;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>