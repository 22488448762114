<template>
  <div class="TangJia" v-loading="loading">
    <!--春季：3-5月-->
    <div class="spring" v-if="nowMoth >= 3 && nowMoth <= 5">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key='"spring"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/TangJia/spring_jianjie.png" alt="" />
        <div class="desc">
          果农们常年坚持使用农家肥、科学疏花疏果，有效保证了品质，因此果树结出的果实不仅颗粒大、水分足，口感也很好。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/orange.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#BB600C"
      ></GovernmentServices>
    </div>
    <!-- 夏季：6-8月 -->
    <div class="summer" v-else-if="nowMoth >= 6 && nowMoth <= 8">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in summerImgList" :key='"summer"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/TangJia/summer_jianjie.png" alt="" />
        <div class="desc">
          果农们常年坚持使用农家肥、科学疏花疏果，有效保证了品质，因此果树结出的果实不仅颗粒大、水分足，口感也很好。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#920C05"
      ></GovernmentServices>
    </div>
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-else>
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in autumnImgList" :key='"autumn"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/TangJia/autumn_jianjie.png" alt="" />
        <div class="desc">
          果农们常年坚持使用农家肥、科学疏花疏果，有效保证了品质，因此果树结出的果实不仅颗粒大、水分足，口感也很好。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#920C05"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "TangJia",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "唐家镇",
      keywords: "",
      springImgList: [
        { imgUrl: require("../../../assets/img/TangJia/banner.jpg") },
        { imgUrl: require("../../../assets/img/TangJia/spring_banner1.jpg") },
        { imgUrl: require("../../../assets/img/TangJia/spring_banner2.jpg") },
      ],
      summerImgList: [
        { imgUrl: require("../../../assets/img/TangJia/banner.jpg") },
        { imgUrl: require("../../../assets/img/TangJia/summer_banner1.jpg") },
        { imgUrl: require("../../../assets/img/TangJia/summer_banner2.jpg") },
        { imgUrl: require("../../../assets/img/TangJia/summer_banner3.jpg") },
      ],
      autumnImgList: [
        { imgUrl: require("../../../assets/img/TangJia/banner.jpg") },
        { imgUrl: require("../../../assets/img/TangJia/autumn_banner1.jpg") },
        { imgUrl: require("../../../assets/img/TangJia/autumn_banner2.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #da6800;
  font-size: 20px;
}
/deep/.summer .el-input__suffix {
  color: #c7211d;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #da6800;
}
/deep/.autumn  .el-carousel__indicator.is-active button {
  background-color: #ad223d;
}
/deep/ .el-carousel__indicator.is-active button {
  background-color: #c7211d;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>